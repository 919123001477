import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import BlurUpImage from "../constant/BlurUpImage";

import { templates as constantTemplates } from "../../constant/templates";
import { mediaUrl } from "../../constant/config";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  gridItemExample: {
    marginTop: theme.spacing(8),
  },
  example: {
    padding: 0,
    display: "flex",
    maxWidth: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
    margin: "0px auto 128px",
  },
  mainTitle: {
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5rem",
    },
  },
  startNowButton: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      fontWeight: "500",
    },
  },
}));

const ExamplesPage = props => {
  const classes = useStyles(props);

  const handleStartNow = (e, label) => {
    trackCustomEvent({
      category: "Start Now",
      action: "Click",
      label: label,
      value: 0,
    });

    navigate("https://studio.app-mockup.com");
  };

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.mainTitle} variant="h2">
                  Examples
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ margin: "10px auto", maxWidth: 600, textAlign: "center" }}>
                <Typography variant="h6">
                  We have recreated some of the best looking screenshots for both iOS and Android to showcase the power of what you are able
                  to create using AppMockUp Studio.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                className={classes.startNowButton}
                size="large"
                color="primary"
                variant="contained"
                disableElevation
                onClick={e => handleStartNow(e, "Top Title")}
              >
                Start Now
              </Button>
            </Grid>

            <Grid className={classes.gridItemExample} item xs={12}></Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            {constantTemplates.allSlugs.map(templateId => {
              const template = constantTemplates.bySlug[templateId];

              const image = {
                id: `${template.slug}-preview`,
                alt: template.name,
                title: template.name,
                src: `${mediaUrl}/assets/images/templates/${template.slug}-preview.png`,
                blurSrc: `${mediaUrl}/assets/images/templates/${template.slug}-preview-pixelated.png`,
                size: { width: 720, height: 389 },
              };

              return (
                <React.Fragment key={template.slug}>
                  <Grid item xs={12} style={{ marginBottom: 32 }}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="h4">
                          <strong>{template.name}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          className={classes.startNowButton}
                          size="large"
                          color="primary"
                          variant="contained"
                          disableElevation
                          onClick={e => handleStartNow(e, "Examples")}
                          style={{ float: "right" }}
                        >
                          Start Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.example}>
                    <BlurUpImage image={image} />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

ExamplesPage.propTypes = {};

export default ExamplesPage;
