import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import ExamplesPage from "../components/Examples/ExamplesPage";

const Examples = () => {
  return (
    <Root>
      <SEO title="Examples" />
      <ExamplesPage />
    </Root>
  );
};

export default Examples;
