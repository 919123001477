export const templates = {
  bySlug: {
    "airbnb-template": {
      slug: "airbnb-template",
      name: "Airbnb",
      type: "free",
      category: "travel-and-local",
    },
    "apple-music-template": {
      slug: "apple-music-template",
      name: "Apple Music",
      type: "free",
      category: "music",
    },
    "blink-travel-template": {
      slug: "blink-travel-template",
      name: "Blink Travel",
      type: "free",
      category: "travel-and-local",
    },
    "dropbox-template": {
      slug: "dropbox-template",
      name: "Dropbox",
      type: "free",
      category: "productivity",
    },
    "messenger-template": {
      slug: "messenger-template",
      name: "Messenger",
      type: "free",
      category: "social-networking",
    },
    "medium-template": {
      slug: "medium-template",
      name: "Medium",
      type: "free",
      category: "entertainment",
    },
    "netflix-template": {
      slug: "netflix-template",
      name: "Netflix",
      type: "free",
      category: "entertainment",
    },
    "slack-template": {
      slug: "slack-template",
      name: "Slack",
      type: "free",
      category: "productivity",
    },
    "snapchat-template": {
      slug: "snapchat-template",
      name: "Snapchat",
      type: "free",
      category: "social-networking",
    },
    "whatsapp-template": {
      slug: "whatsapp-template",
      name: "WhatsApp",
      type: "free",
      category: "social-networking",
    },
    "food-template-3": {
      slug: "food-template-3",
      name: "Food Template 3",
      type: "free",
      category: "food-and-drink",
    },
    "food-template-4": {
      slug: "food-template-4",
      name: "Food Template 4",
      type: "free",
      category: "food-and-drink",
    },
    "entertainment-app-template-1": {
      slug: "entertainment-app-template-1",
      name: "Entertainment App Template 1",
      type: "free",
      category: "entertainment",
    },
    "photo-video-template-1": {
      slug: "photo-video-template-1",
      name: "Photo & Video Template 1",
      type: "free",
      category: "photo-and-video",
    },
    "uber-eats-template": {
      slug: "uber-eats-template",
      name: "Uber Eats",
      type: "free",
      category: "food-and-drink",
    },
  },
  allSlugs: [
    "airbnb-template",
    "apple-music-template",
    "blink-travel-template",
    "dropbox-template",
    "messenger-template",
    "medium-template",
    "netflix-template",
    "slack-template",
    "snapchat-template",
    "whatsapp-template",
    "food-template-3",
    "food-template-4",
    "entertainment-app-template-1",
    "photo-video-template-1",
    "uber-eats-template",
  ],
};
